* {
  margin: 0;
  font-family: 'Helvetica Neue',
    sans-serif;
}
.product-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}


body {
  background-color: rgb(233, 230, 250);
  /*background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(58,9,121,1) 16%, rgba(137,75,168,1) 32%, rgba(54,60,151,1) 49%, rgba(89,35,188,1) 67%, rgba(34,42,127,1) 85%, rgba(205,0,255,1) 100%);*/
}

  .product-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
  }

  .product-grid-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .product-image {
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
  }

  .product-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .product-details{
    margin-top:10px;
  }

  .product-details h5 {
    font-size: 15px;
    text-align: center;
    margin: 10px;
    text-transform: uppercase;
    color: #4f2020;
  }

  .product-details p {
    font-size: 0.9em;
    color: #333;
    text-align: right;
  }

  td{
    font-size: 0.9em;
  }