/* src/components/ProductModal.scss */

.modal-large .modal-dialog {
  max-width: 90%; /* Adjust the width as needed */
}
.heading{
  font-size: 18px;
  color: #7b5353;
}
.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.modal-body {
  padding: 2rem;
}

.product-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image {
  width: 100%;
  max-width: 300px;
  object-fit: contain;
}

.info-col {
  padding-left: 1rem;
  font-size: 0.9em;
}

.product-details  .price {
  font-size: 1rem;
  color: #1c4c30;
  font-weight: bold;
  text-align: left;
}

.branch-availability {
  margin: 1.5rem;
  font-size: 0.9rem;
}

.product-details p {
  font-size: 0.9em;
  color: #333;
  text-align: left;
}
.branch-item {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-dialog{
  max-width: 60% !important;
}

.branch-availability {
  margin-top: 20px;
}

.branch-list {
  display: flex;
  flex-wrap: wrap;
}

.branch-item {
  flex: 1 1 50%;
  padding: 5px;
}

.branch-item p {
  margin: 0;
}

.rack-management {
    padding: 15px;

    .rack-update-form {
        background-color: #f8f9fa;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .rack-history {
        margin-top: 20px;
        
        table {
            font-size: 0.9em;
        }
    }
}

.nav-tabs {
    margin-bottom: 20px;
}

.tab-content {
    padding: 15px 0;
}

.confirmation-modal {
    .modal-content {
        border-radius: 8px;
        width: 60%;
    margin: auto;
    }
    
    .modal-header {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid #dee2e6;
    }
    
    .modal-footer {
        border-top: 1px solid #dee2e6;
        gap: 0.5rem;
    }
}